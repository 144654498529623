import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-socialntw',
  templateUrl: './socialntw.component.html',
  styleUrls: ['./socialntw.component.css']
})
export class SocialntwComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
