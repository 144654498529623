const dominicanPlayersIds2019 = [472551,650556,542436,642715,593643,660896,645261,622505,501659,609280,606157,606478,
  623214,520980,622783,639373,593528,593647,650893,466320,429664,650895,622491,456078,446868,612434,516770,542585,
  661403,517008,614173,622772,443558,456501,660853,600887,646240,622766,660546,594694,622554,429665,608032,544727,
  593140,622774,658551,596748,658305,606424,544725,472610,596825,554340,593334,460576,570481,620453,594056,594311,
  642721,608597,593700,593523,606192,516969,594011,605301,650391,501571,659275,657656,600917,642736,434538,625643,
  672773,608475,622534,606466,516782,593372,608577,606167,625510,642336,622492,573668,593423,606160,606625,622168,
  593576,504379,456051,467100,456488,542303,606303,570240,642547,593974,503449,606131,501381,570256,593871,527049,
  405395,434670,608070,606162,600466,614177,622569,570663,645302,407845,622446,593144,500135,622864,650738,642708,
  600968,517593,596142,593934,467793,542454,642701,570267,429722,516416,622663,600474,642423,642397,665742,467008,
  593833,606132,665487,570632,620446,664285,570488,542340,527055,450172,593679,660623]
export default dominicanPlayersIds2019;
