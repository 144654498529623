// const venezuelanPlayersIds2019 = [660670,501303,542583,514888,621237,501625,640470,462101,606115,650333,600858,553902,658648,501593,620439,
//   452678,408234,471911,620454,605612,465041,468504,455139,630023,575929,602074,553869,545064,500871,642731,527038,521655,575070,520471,541645,
//   491646,471865,503556,660813,492802,500610,448855,641645,514917,650382,622694,433587,491676,546318,542255,621592,506702,641154,608566,500874,
//   622795,553882,600524,596059,542364,591741,467827,444482,606299,541650,527048,433589,444489,620982,517369,650671,591693,467092,650859,622682,
//   593958,642578,500743,444468,602922,614179,506703,434671,570560,467055,623993,622608,500208,553993,660761,624133,491696,622786,620443,650402,
//   591720,541600,591971,545121,553878]
// export default venezuelanPlayersIds2019;

// const venezuelanPlayersIds2019 = [677800,682668,660670,514888,621237,682626,640470,606115,672356,650333,658648,640492,678489,678545,660825,676130,665828,682616,660636,666721,694192,661388,575929,685744,553869,642731,666023,527038,695865,677651,672580,541645,665926,682790,660813,641645,672782,665912,672578,645444,683568,661440,641154,672779,665561,667356,682641,683627,655316,672456,672515,608566,665966,553882,658668,686780,694037,691384,672724,677592,521692,650907,527048,691620,676017,650859,677587,593958,500743,660688,683690,623993,622608,672820,670869,660761,663158,553993,624133,645305,650402,678662,545121,678391,672582,806956];
// export default venezuelanPlayersIds2019;

// TODOS LOS CODIGOS DE LOS JUGADORES DE OCT) de 2025
const venezuelanPlayersIds = [ 
    // 646242, 553869, 663158, 650333, 666785, 666632, 699134, 660825, 660844, 
//     622608, 608566, 681623, 
//     683627, 700164, 545121, 553993, 593958, 620454, 650907, 645444, 666661, 672515, 700270, 678489, 
//     678493, 692544, 521692, 605612, 666023, 672578, 672582, 672580, 681168, 679883, 500743, 542208, 
//     553988, 660813, 694037, 682949, 677570, 680846, 699062, 683618, 694208, 471911, 516853, 612797, 
//     679758, 665645, 665828, 665648, 672782, 672724, 678707, 700287, 678391, 677592, 683079, 683679, 
//     699126, 665966, 667356, 677800, 691370, 691373, 691384, 699157

    // 677578, 660670, 606115, 506702, 683690, 672373, 682769, 641154, 673394, 665846, 682692, 514888, 
    // 620449, 685744, 641645, 664351, 677651, 660634, 682626, 699305, 620443, 640492, 542364, 682668, 640470, 
    // 676130, 681739, 660688, 665953, 660766, 674285, 691251, 514888, 685744, 
    // 806441, 682692, 677651, 670868, 544150, 620439, 670869, 691373, 665966, 677800, 691370, 699157, 667356, 
    // 691384, 665648, 699126, 700287, 683679, 665828, 677592, 672782, 683079, 801276, 672724, 678391, 471911, 
    // 665645, 678707, 612797, 679758, 516853, 665926, 645305, 692478, 623993, 682785, 665561, 621237, 691893, 
    // 691330, 614179, 624133, 672613, 665859, 650402, 695865, 672456, 678662, 
    // 608566, 622608, 553993, 545121, 678493, 678489, 700270, 692544, 666661, 
    // 593958, 677587, 672356, 684222, 691422, 682897, 699114, 703150, 806957, 691641, 682641, 678545, 527038, 
    // 694196, 681168, 553882, 672820, 682790, 683568, 527048, 694208, 680846, 553988, 542208, 694037, 
    // 645444, 620454, 672515, 650907, 700270, 692544, 666661, 593958, 642731, 
    // 700164, 681623, 608566, 683627, 703150, 806957, 655316, 694192, 661388, 671272, 
    // 694206, 691620, 640902, 650333, 666632, 553869, 666785, 699134, 663158, 660844, 660825, 646242, 570560, 
    // 808129, 650859, 665861, 650671, 660761, 666023, 605612, 672578, 521692, 679883, 679200, 672582, 683618, 
    // 677570, 691558, 500743, 660813, 699062, 682949, 575929, 701800, 686780, 692405, 665996, 691185, 691594, 
    // 622786,806956, 666200, 647351];

    808104, 699157, 691384, 691373, 691370, 691385, 677800, 667356 ,665966, 800510, 695865, 672613, 
    691251, 665953, 673395, 660688, 674285, 660766, 692371, 684852, 683690, 677578, 660670, 606115, 506702, 
    699126, 800510, 700287, 695865, 672456, 665648, 665859, 650402, 692478, 682785, 670869, 665926, 645305, 
    620439, 623993, 544150, 691913, 682616, 682769, 685712, 672373, 673394, 670868, 641154, 692405, 698929, 692390, 
    691185, 691594, 691582, 701800, 681450, 665996, 686780, 622786, 575929, 801276, 691330, 683610, 683679, 691893, 
    677592, 683079, 678391, 672724, 678707, 665828, 672782, 672376, 665561, 665645, 679758, 621237, 624133, 612797, 
    614179, 516853, 471911, 821252, 808154, 800331, 828592, 806441, 808129, 699305, 820884, 803241, 698933, 691710, 
    694208, 700322, 682668, 702878, 691186, 682626, 682692, 680846, 681739, 684483, 685744, 672620, 680933, 694037, 
    660634, 676130, 664346, 665846, 665861, 660761, 640492, 620443, 664351, 677651, 650859, 640470, 620449, 650671, 
    641645, 570560, 542364, 553988, 514888, 542208, 806957, 800198, 699114, 703150, 699912, 699134, 691641, 692544, 
    683490, 678493, 700270, 678489, 672515, 666661, 666632, 650333, 666785, 645444, 650907, 640902, 620454, 593958, 
    545121, 553993, 663158, 553869, 808396, 805052, 691422, 692852, 683588, 682790, 683568, 684222, 682897, 
    681168, 677587, 669413, 672820, 672356, 553882, 527048, 815929, 692044, 692348, 694196, 
    700164, 683588, 682641, 682674, 678662, 683627, 678545, 682276, 681623, 660844, 646242, 660825, 642731, 608566, 
    622608, 527038, 800424, 694192, 800325, 802192, 694206, 699130, 691616, 691558, 692334, 683618, 691600, 682633, 
    691620, 677570, 699062, 679883, 682949, 672580, 671272, 672582, 660813, 661388, 655316, 672578, 679200, 666023, 
    605612, 521692, 500743, 806956,666200, 647351,687282]
export default venezuelanPlayersIds;

// TODOS LOS CODIGOS DE LOS JUGADORES DEL 21 de FEBRER) de 2025
//  const venezuelanPlayersIds = [678931, 677800, 682668, 660670, 640470, 672356, 678489, 682616, 666721, 694192, 685744, 695865, 682790, 660813, 665912, 672578, 683568, 641154, 665561, 667356, 682641, 683627, 655316, 672456, 608566, 665966, 694037, 691384, 527048, 691620, 676017, 683690, 545121, 678391,806956,
    
//    514888,462101,656196,606115,672356,553902,642772,660825,593993,621593,605612,455139,
//    602074,500871,666023,527038,520471,541645,665926,664332,492802,645444,542255,672779,
//    665561,553882,658668,444482,672724,542208,541650,521692,650907,578570,467092,650859,
//    665861,602922,506703,570560,467055,623993,670869,553993,622786,650402,591971,546318,
//    672582,682626,611093,665645,667749,672782,672832,647351,666200,663158,660766,642769,
//    501303,622758,542583,622459,621237,656032,501625,640470,689577,665918,650333,600858,
//    672555,641319,501593,640492,620439,678545,591611,666598,433217,660684,452678,408234,
//    665828,650700,471911,613546,665776,542419,660636,612792,620449,661411,465041,468504,
//    665953,622482,630023,666674,661388,575929,660630,646241,666452,553869,545064,646242,
//    642731,660662,600898,570717,521655,575070,660614,671272,660564,600902,665648,608422,
//    650506,642857,471865,503556,660813,660508,500610,448855,641645,670868,642511,672578,
//    514917,650382,622694,433587,491676,665912,591712,660634,672544,621592,506702,650428,
//    661440,446653,660686,641154,606208,600921,650347,670867,608566,500874,680932,667356,
//    642337,655316,672515,620445,665922,682774,593163,600524,596059,660690,650883,542364,
//    591741,686780,620444,606299,640902,677592,656024,658530,527048,672596,433589,650658,
//    444489,665836,642513,660749,650671,591693,622682,660844,677587,630034,593958,642578,
//    677601,670550,500743,444468,614179,660688,650362,593137,656004,645305,434671,645307,
//    650694,622608,658531,500208,672820,660761,624133,542513,624636,620443,650660,591720,
//    541600,665987,545121,660631,622415,664337,642508,677651,678662,676130,650339, 658648, 
//    620454, 666721, 672580, 553988, 672839];
//  export default venezuelanPlayersIds;
