// const dominicansVenezuelansPlayersIds = [660670,501303,542583,514888,621237,501625,640470,462101,606115,650333,600858,553902,
//   658648,501593,620439,452678,408234,471911,620454,605612,465041,468504,455139,630023,575929,602074,553869,545064,
//   500871,642731,527038,521655,575070,520471,541645,491646,471865,503556,660813,492802,500610,448855,641645,514917,
//   650382,622694,433587,491676,546318,542255,621592,506702,641154,608566,500874,622795,553882,600524,596059,542364,
//   591741,467827,444482,606299,541650,527048,433589,444489,620982,517369,650671,591693,467092,650859,622682,593958,
//   642578,500743,444468,602922,614179,506703,434671,570560,467055,623993,622608,500208,553993,660761,624133,491696,
//   622786,620443,650402,591720,541600,591971,545121,553878,
//   472551,650556,542436,642715,593643,660896,645261,622505,501659,609280,606157,606478,623214,520980,622783,639373,
//   593528,593647,650893,466320,429664,650895,622491,456078,446868,612434,516770,542585,661403,517008,614173,622772,
//   443558,456501,660853,600887,646240,622766,660546,594694,622554,429665,608032,544727,593140,622774,658551,596748,
//   658305,606424,544725,472610,596825,554340,593334,460576,570481,620453,594056,594311,642721,608597,593700,593523,
//   606192,516969,594011,605301,650391,501571,659275,657656,600917,642736,434538,625643,672773,608475,622534,606466,
//   516782,593372,608577,606167,625510,642336,622492,573668,593423,606160,606625,622168,593576,504379,456051,467100,
//   456488,542303,606303,570240,642547,593974,503449,606131,501381,570256,593871,527049,405395,434670,608070,606162,
//   600466,614177,622569,570663,645302,407845,622446,593144,500135,622864,650738,642708,600968,517593,596142,593934,
//   467793,542454,642701,570267,429722,516416,622663,600474,642423,642397,665742,467008,593833,606132,665487,570632,
//   620446,664285,570488,542340,527055,450172,593679,660623]
//   export default dominicansVenezuelansPlayersIds;


const dominicansVenezuelansPlayersIds = [472551,650556,642758,642715,677941,656180,666176,650520,672851,605113,593643,570489,680769,641302,664119,669397,592105,
501659,514888,669137,676070,664011,623454,641313,462101,609280,656196,665947,660620,606115,571448,668227,624414,676879,
553902,668731,664056,678225,676599,672275,641329,668787,542908,676625,605130,605131,607457,642772,660825,607461,666179,
605137,621532,643217,605138,542932,605141,666182,656232,624415,518464,668709,664761,624416,659070,542963,664069,593993,
621446,593647,670712,682204,664913,542979,660644,663460,656266,680814,669394,641425,683737,621439,650893,594777,641432,
600869,592192,666185,656290,572761,682130,641447,621593,605612,488771,666801,516770,650489,665482,641453,656308,455139,
665862,664199,621348,664076,665019,687714,641470,643265,663526,622226,543068,640444,665833,664202,666915,571602,656353,
448801,641505,501981,543089,451584,669008,602074,621008,660853,621573,681909,656364,657557,641513,646240,649557,656371,
650331,650490,666783,543105,670426,650959,661832,669023,656382,643289,608648,656388,677649,680776,660710,669242,605218,
595939,641553,543135,621169,500871,664045,669746,656413,607188,607200,543148,666128,666023,605233,656427,664058,527038,
665650,677551,571676,640449,453943,658069,608334,606149,668678,608336,520471,607231,541645,677593,671277,664247,572863,
641598,621248,656450,656455,656457,656467,502671,596295,664332,624427,592346,570481,670768,580792,624503,669357,594838,
605254,492802,656484,641632,682985,606988,677954,675627,665489,666971,642721,608597,606992,664785,571735,669742,643348,
641656,670456,641658,621098,680683,607560,674677,656509,656514,663647,641680,623168,670125,571771,593523,628450,671056,
645444,543308,656537,656541,546994,669374,595988,502706,605280,571788,607572,675448,628451,542255,664728,656577,656578,
543351,608671,643376,445055,641726,666204,656582,641733,640453,592444,663330,622100,592450,673962,621076,641743,453527,
664918,608348,643393,596146,666197,592464,643396,650633,664068,543401,666135,552640,656620,668800,518902,596012,607216,
607249,571871,665417,641771,501571,656638,669127,641778,657656,518934,605338,641793,669674,621005,670032,672640,663992,
664040,682617,476595,641807,669157,605353,543482,543484,641820,657672,665561,622534,669016,606466,606336,641829,676733,
661527,621086,425772,621500,676755,643436,664983,663411,656701,676801,669200,519008,641851,663630,656716,641857,640457,
683232,625510,623180,664901,666141,669214,593160,673516,663402,675649,641882,669304,571951,668751,425877,660162,666160,
660707,592567,519048,621559,656775,622168,680765,553882,640459,663734,641914,656794,621028,596056,669738,467100,624513,
683083,656811,641933,656807,666931,658668,672391,665560,681546,670462,572008,656821,641943,623381,682418,665161,444482,
672724,622780,542208,541650,521692,596071,656847,640461,615697,650907,570256,593871,670149,572041,457763,547001,668472,
527049,578570,500779,679727,670042,641995,467092,600466,642002,656883,676646,621545,592669,650859,621052,622103,668804,
595375,663586,621458,605441,623222,665861,621002,570663,645302,615699,663898,573669,642028,573124,679032,670351,668942,
602922,666163,671271,543726,573131,666164,693306,506703,596142,660821,667376,570560,467055,663776,593934,467793,642701,
429722,623993,594988,677415,656941,643524,608369,543760,600474,622046,543768,642068,642073,669699,670097,642423,643539,
642350,596103,663629,656970,607345,675656,656976,474319,691016,669256,456781,624585,624641,670869,656986,519306,665661,
605488,608715,605490,664054,664057,621514,681082,664702,519326,596117,572180,680552,621020,596119,519333,606132,643565,
621438,642133,663799,642136,642137,657041,669352,572204,605507,622786,630111,623437,647351,650402,676701,644433,660294,
663656,668930,607208,670955,591971,642165,678246,662139,683734,543877,623205,668723,543883,596129,572228,613564,664774,
621097,667727,657088,595453,665120,621493,657089,476589,671221,657093,607179,622256,666213,673995,642201,643603,663605,
592859,642211,663897,680963,677951,592879,572406,547172,657136,642221,621056,657140,605541,668824,642851,670370,669945,
664874,643615]
export default dominicansVenezuelansPlayersIds;
