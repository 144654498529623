// const venezuelanPlayersIds = [650556,642715,672851,660896,682634,691182,609280,683522,678394,682653,672860,674370,669854,623211,666745,682877,660644,665795,650893,680983,691406,672744,679734,622491,612434,666801,683409,542585,661403,682729,672710,692030,665833,674444,621016,692414,650559,682829,691277,660853,646240,673237,622554,666808,677649,661395,622766,669289,608032,665734,665877,678606,664314,677551,666818,665620,472610,554340,661563,669796,606192,664299,666204,650391,657656,600917,678760,672640,682617,625643,622534,606466,682622,516782,628708,679885,682619,682657,622761,665622,675848,691441,593423,660707,606160,666624,664294,682610,593576,678877,661383,682847,542303,665506,606303,665625,678894,642547,593974,622780,672695,665161,593871,691587,672761,682663,691267,608070,670990,622569,645302,691181,679563,677594,678495,666720,679032,666703,642708,665923,467793,666619,622663,670870,642350,666277,667755,642397,665742,650911,596142,660821,664350,665487,665750,677588,682842,665839,664285,672841,681190,660623,679033]
// export default venezuelanPlayersIds;

// const venezuelanPlayersIds = [660670,501303,542583,514888,621237,501625,640470,462101,606115,650333,553902,501593,620439,642772,593993,452678,
//   408234,471911,621593,605612,465041,468504,455139,630023,661388,575929,553869,545064,500871,642731,527038,521655,520471,541645,677651,665926,
//   503556,660813,500610,448855,641645,672578,514917,650382,622694,591712,542255,506702,661440,641154,608566,500874,553882,600524,596059,658668,
//   591741,444482,606299,541650,527048,521692,433589,444489,650671,467092,650859,622682,642578,670550,500743,444468,614179,660688,656004,645305,
//   434671,645307,570560,467055,623993,622608,553993,660761,624133,622786,620443,650402,545121,664337];
//   export default venezuelanPlayersIds;

// VENEZOLANOS ACTIVOS SRPING TRAINNING 2021 al 28 de Febrero
// const venezuelanPlayersIds = [514888,462101,656196,606115,672356,553902,642772,660825,593993,621593,605612,455139,602074,500871,666023,527038,520471,
// 541645,665926,664332,492802,645444,542255,665561,553882,658668,444482,672724,542208,541650,521692,650907,578570,467092,650859,665861,602922,506703,
// 570560,467055,623993,670869,553993,622786,650402,591971];
//   export default venezuelanPlayersIds;

// VENEZOLANOS ACTIVOS SRPING TRAINNING 2021 al 01 de marzo
// const venezuelanPlayersIds = [665966, 514888,462101,656196,606115,672356,553902,642772,660825,593993,621593,605612,455139,
//   602074,500871,666023,527038,520471,541645,665926,664332,492802,645444,542255,672779,665561,553882,658668,444482,
//   672724,542208,541650,521692,650907,578570,467092,650859,665861,602922,506703,570560,467055,623993,670869,553993,
//   622786,650402,591971,546318,672582,682626,611093,665645,667749,672782,672832,647351,666200,663158,660766, 642769,660670,501303,622758,542583,622459,621237,656032,501625,640470,689577,665918,
//     650333,600858,672555,641319,501593,640492,620439,678545,591611,666598,433217,660684,452678,
//     408234,665828,650700,471911,613546,665776,542419,660636,612792,620449,661411,465041,468504,
//     665953,622482,630023,666674,661388,575929,660630,646241,666452,553869,545064,646242,642731,
//     660662,600898,570717,521655,575070,660614,671272,660564,600902,665648,608422,650506,642857,
//     471865,503556,660813,660508,500610,448855,641645,670868,642511,672578,514917,650382,622694,
//     433587,491676,665912,591712,660634,672544,621592,506702,650428,661440,446653,660686,641154,
//     606208,600921,650347,670867,608566,500874,680932,667356,642337,655316,672515,620445,665922,
//     682774,593163,600524,596059,660690,650883,542364,591741,686780,620444,606299,640902,677592,
//     656024,658530,527048,672596,433589,650658,444489,665836,642513,660749,650671,591693,622682,
//     660844,677587,630034,593958,642578,677601,670550,500743,444468,614179,660688,650362,593137,
//     656004,645305,434671,645307,650694,622608,658531,500208,672820,660761,624133,542513,624636,
//     620443,650660,591720,541600,665987,545121,660631,622415,664337,642508, 677651, 678662, 676130, 
//     650339, 658648, 620454, 666721, 672580, 553988, 672839, 677800, 679346, 677592];
//   export default venezuelanPlayersIds;

// TODOS LOS JUGADORES DE VENEZUELA EN TRIPLE A
// const venezuelanPlayerIds = [
//   698989,678690,683733,800421,678493,686404,699082,699130,699002,
// 683450,
//   682719,
//   693805,
//   699114,
//   692408,
//   699126,
//   700164,
//   691629,
//   800522,
//   684109,
//   691893,
//   701179,
//   698911,
//   682769,
//   691913,
//   700341,
//   691370,
//   702847,
//   678652,
//   683569,
//   694181,
//   692390,
//   681450,
//   694227,
//   699314,
//   692369,
//   694185,
//   691600,
//   694180,
//   684133,
//   693013,
//   683609,
//   698927,
//   683536,
//   692371,
//   699912,
//   689951,
//   800303,
//   683612,
//   691863,
//   683742,
//   694188,
//   691373,
//   703190,
//   691289,
//   692623,
//   699090,
//   683841,
//   800338,
//   683510,
//   678582,
//   691414,
//   678404,
//   683681,
//   694943,
//   699121,
//   803247,
//   694206,
//   683618,
//   696095,
//   698933,
//   805082,
//   672620,
//   692632,
//   678973,
//   691453,
//   692222,
//   683639,
//   693732,
//   678219,
//   700296,
//   678917,
//   699157,
//   692021,
//   694229,
//   700335,
//   694209,
//   686544,
//   684126,
//   691571,
//   698965,
//   703835,
//   692350,
//   700354,
//   692452,
//   700288,
//   683079,
//   682695,
//   699052,
//   694207,
//   691973,
//   678288,
//   682732,
//   683677,
//   800017,
//   678378,
//   678986,
//   699305,
//   699215,
//   678796,
//   691627,
//   699276,
//   702878,
//   686339,
//   699151,
//   692633,
//   691261,
//   672558,
//   691616,
//   691710,
//   686402,
//   691437,
//   678800,
//   685712,
//   699062,
//   699393,
//   693807,
//   691922,
//   701865,
//   682714,
//   692377,
//   692653,
//   691177,
//   700357,
//   700292,
//   696098,
//   682801,
//   691905,
//   702409,
//   701329,
//   691418,
//   683658,
//   678828,
//   682934,
//   683389,
//   684712,
//   691466,
//   678807,
//   680887,
//   698934,
//   683665,
//   692044,
//   695360,
//   682803,
//   699703,
//   805144,
//   800198,
//   800106,
//   685824,
//   692513,
//   694196,
//   691908,
//   692616,
//   691171,
//   683652
// ];
// export default venezuelanPlayerIds;



  

// TODOS LOS CODIGOS DE LOS JUGADORES DE OCT) de 2025
  const venezuelanPlayersIds = [
    // 677800,682668,660670,501303,514888,621237,682626,640470,
    // 606115,672356,650333,658648, 640492,678489,660825,676130,665828,471911,660636,665953,
    // 630023,694192,694192,664351,
    // 661388,575929,700270,553869,646242,642731,666023,527038,672580,541645,665926,660813,
    // 641645,672782,683618,672578,622694,645444,683568,641154,665561,682641,683627,655316,
    // 672456,672515,608566,665966,553882,658668,542364,686780,694037,444482,672724,640902,
    // 521692,650907,620982,650671,650859,660844,677587,593958,500743,614179,660688,674285,
    // 691594,622608,672820,670869,660761,663158,544150,553993,624133,645305,622786,620443,
    // 650402,678662,545121,660766,672582,
  
    // 646242, 553869, 663158, 650333, 666785, 666632, 699134, 660825, 660844, 622608, 608566, 681623, 
    // 683627, 700164, 545121, 553993, 593958, 620454, 650907, 645444, 666661, 672515, 700270, 678489, 
    // 678493, 692544, 521692, 605612, 666023, 672578, 672582, 672580, 681168, 679883, 500743, 542208, 
    // 553988, 660813, 694037, 682949, 677570, 680846, 699062, 683618, 694208, 471911, 516853, 612797, 
    // 679758, 665645, 665828, 665648, 672782, 672724, 678707, 700287, 678391, 677592, 683079, 683679, 
    // 699126, 665966, 667356, 677800, 691370, 691373, 691384, 699157,806956

    // 677578, 660670, 606115, 506702, 683690, 672373, 682769, 641154, 673394, 665846, 514888, 
    // 620449, 685744, 641645, 664351, 677651, 660634, 682626, 699305, 620443, 640492, 542364, 682668, 640470, 
    // 676130, 681739, 660688, 665953, 660766, 674285, 691251, 514888, 685744, 
    // 806441, 682692, 677651, 670868, 544150, 620439, 670869, 691373, 665966, 677800, 691370, 699157, 667356, 
    // 691384, 665648, 699126, 700287, 683679, 665828, 677592, 672782, 683079, 801276, 672724, 678391, 471911, 
    // 665645, 678707, 612797, 679758, 516853, 665926, 645305, 692478, 623993, 682785, 665561, 621237, 691893, 
    // 691330, 614179, 624133, 672613, 665859, 650402, 695865, 672456, 678662, 
    // 608566, 622608, 553993, 545121, 678493, 678489, 700270, 692544, 666661, 
    // 593958, 677587, 672356, 684222, 691422, 682897, 699114, 703150, 806957, 691641, 682641, 678545, 527038, 
    // 694196, 681168, 553882, 672820, 682790, 683568, 527048, 694208, 680846, 553988, 542208, 694037, 
    // 645444, 620454, 672515, 650907, 700270, 692544, 666661, 593958, 642731, 
    // 700164, 681623, 608566, 683627, 806957, 655316, 694192, 661388, 671272, 
    // 694206, 691620, 640902, 650333, 666632, 553869, 666785, 699134, 663158, 660844, 660825, 646242, 570560, 
    // 808129, 650859, 665861, 650671, 660761, 666023, 605612, 672578, 521692, 679883, 679200, 672582, 683618, 
    // 677570, 691558, 500743, 660813, 699062, 682949, 575929, 701800, 686780, 692405, 665996, 691185, 691594, 
    // 622786, 806956, 666200, 647351


    808104, 699157, 691384, 691373, 691370, 691385, 677800, 667356 ,665966, 800510, 695865, 672613, 
    691251, 665953, 673395, 660688, 674285, 660766, 692371, 684852, 683690, 677578, 660670, 606115, 506702, 
    699126, 800510, 700287, 695865, 672456, 665648, 665859, 650402, 692478, 682785, 670869, 665926, 645305, 
    620439, 623993, 544150, 691913, 682616, 682769, 685712, 672373, 673394, 670868, 641154, 692405, 698929, 692390, 
    691185, 691594, 691582, 701800, 681450, 665996, 686780, 622786, 575929, 801276, 691330, 683610, 683679, 691893, 
    677592, 683079, 678391, 672724, 678707, 665828, 672782, 672376, 665561, 665645, 679758, 621237, 624133, 612797, 
    614179, 516853, 471911, 821252, 808154, 800331, 828592, 806441, 808129, 699305, 820884, 803241, 698933, 691710, 
    694208, 700322, 682668, 702878, 691186, 682626, 682692, 680846, 681739, 684483, 685744, 672620, 680933, 694037, 
    660634, 676130, 664346, 665846, 665861, 660761, 640492, 620443, 664351, 677651, 650859, 640470, 620449, 650671, 
    641645, 570560, 542364, 553988, 514888, 542208, 806957, 800198, 699114, 703150, 699912, 699134, 691641, 692544, 
    683490, 678493, 700270, 678489, 672515, 666661, 666632, 650333, 666785, 645444, 650907, 640902, 620454, 593958, 
    545121, 553993, 663158, 553869, 808396, 805052, 691422, 692852, 683588, 682790, 683568, 684222, 682897, 
    681168, 677587, 669413, 672820, 672356, 553882, 527048, 815929, 692044, 692348, 694196, 
    700164, 683588, 682641, 682674, 678662, 683627, 678545, 682276, 681623, 660844, 646242, 660825, 642731, 608566, 
    622608, 527038, 800424, 694192, 800325, 802192, 694206, 699130, 691616, 691558, 692334, 683618, 691600, 682633, 
    691620, 677570, 699062, 679883, 682949, 672580, 671272, 672582, 660813, 661388, 655316, 672578, 679200, 666023, 
    605612, 521692, 500743, 806956,666200, 647351,687282

    ];
 export default venezuelanPlayersIds;

//  TODOS LOS CODIGOS DE LOS JUGADORES DEL 21 de FEBRER) de 2025
//  const venezuelanPlayersIds = [678931, 677800, 682668, 660670, 678489, 682616, 666721, 694192, 685744,
//   695865, 682790, 665912, 683568, 667356, 682641, 683627, 672456, 
  // 665966, 694037, 691384, 691620, 676017, 683690, 678391,806956,
  // 514888,462101,656196,606115,672356,553902,642772,660825,593993,621593,605612,455139,
  // 602074,500871,666023,527038,520471,541645,665926,664332,492802,645444,542255,672779,665561,553882,658668,444482,
  // 672724,542208,541650,521692,650907,578570,467092,650859,665861,602922,506703,570560,467055,623993,670869,553993,
  // 622786,650402,591971,546318,672582,682626,611093,665645,667749,672782,672832,647351,666200,663158,660766, 642769,
  // 501303,622758,542583,622459,621237,656032,501625,640470,689577,665918,
  // 650333,600858,672555,641319,501593,640492,620439,678545,591611,666598,433217,660684,452678,
  // 408234,665828,650700,471911,613546,665776,542419,660636,612792,620449,661411,465041,468504,
  // 665953,622482,630023,666674,661388,575929,660630,646241,666452,553869,545064,646242,642731,
  // 660662,600898,570717,521655,575070,660614,671272,660564,600902,665648,608422,650506,642857,
  // 471865,503556,660813,660508,500610,448855,641645,670868,642511,672578,514917,650382,622694,
  // 433587,491676,665912,591712,660634,672544,621592,506702,650428,661440,446653,660686,641154,
  // 606208,600921,650347,670867,608566,500874,680932,667356,642337,655316,672515,620445,665922,
  // 682774,593163,600524,596059,660690,650883,542364,591741,686780,620444,606299,640902,677592,
  // 656024,658530,527048,672596,433589,650658,444489,665836,642513,660749,650671,591693,622682,
  // 660844,677587,630034,593958,642578,677601,670550,500743,444468,614179,660688,650362,593137,
  // 656004,645305,434671,645307,650694,622608,658531,500208,672820,660761,624133,542513,624636,
  // 620443,650660,591720,541600,665987,545121,660631,622415,664337,642508, 677651, 678662, 676130, 650339, 
//   658648, 620454, 666721, 672580, 553988, 672839];
// export default venezuelanPlayersIds;

//  [
//   677800,
//   682668,
//   660670,
//   514888,
//   621237,
//   682626,
//   640470,
//   606115,
//   672356,
//   650333,
//   658648,
//   640492,
//   678489,
//   678545,
//   660825,
//   676130,
//   665828,
//   682616,
//   660636,
//   666721,
//   694192,
//   661388,
//   575929,
//   685744,
//   553869,
//   642731,
//   666023,
//   527038,
//   695865,
//   677651,
//   672580,
//   541645,
//   665926,
//   682790,
//   660813,
//   641645,
//   672782,
//   665912,
//   672578,
//   645444,
//   683568,
//   661440,
//   641154,
//   672779,
//   665561,
//   667356,
//   682641,
//   683627,
//   655316,
//   672456,
//   672515,
//   608566,
//   665966,
//   553882,
//   658668,
//   686780,
//   694037,
//   691384,
//   672724,
//   677592,
//   521692,
//   650907,
//   527048,
//   691620,
//   676017,
//   650859,
//   677587,
//   593958,
//   500743,
//   660688,
//   683690,
//   623993,
//   622608,
//   672820,
//   670869,
//   660761,
//   663158,
//   553993,
//   624133,
//   645305,
//   650402,
//   678662,
//   545121,
//   678391,
//   672582
// ]